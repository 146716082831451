body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: white !important;

}

body, #root {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html, #root {
  height: 100%;
}

.pointer {
  cursor: pointer !important;
}

form .form-label {
  color: black !important;
}

.arrows-container {
  width: 100%;

  display: flex;
  align-items: center;

  z-index: 99;
}

/* Media query only Desktop */
@media (min-width: 768px) {
  .arrows-container {
    position: absolute;
    transform: translateY(50vh);
  }
}

.arrows-container > .arrow {
  position: absolute;
  font-size: 5em;
}

.arrows-container > .arrow.left {
  left: 0
}

.arrows-container > .arrow.right {
  right: 0
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  
  margin-bottom: 1em;
}

.title-container > .title-icon {
  color: black !important;
  position: absolute
}

.title-icon.right {
  right: .5em;
}

.title-icon.left {
  left: .5em;
}

.swiper-wrapper {
  max-width: 100vw;
}

.swiper-slide {
  overflow-y: auto !important;

  display: flex;
  justify-content: center;
}

.searchWrapper {
  border: none !important;
  padding: 0 !important;
}

thead > tr > td {
  font-weight: bold;
}